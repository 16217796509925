var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mods && _vm.products && _vm.selectedProduct)?_c('v-bottom-sheet',{attrs:{"persistent":""},model:{value:(_vm.helper),callback:function ($$v) {_vm.helper=$$v},expression:"helper"}},[_c('v-card',{staticClass:"overflow-auto",attrs:{"color":"brown","elevation":"0","height":_vm.getModProducts.length > 4 ? '760px' : '480px'}},[_c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-6 mb-6",attrs:{"flat":"","color":" brown","width":"100%"}},[_c('v-btn',{staticClass:"pa-0",attrs:{"rounded":"","outlined":"","color":"grey lighten-4","x-large":""},on:{"mouseover":function($event){$event.preventDefault();}}},_vm._l((_vm.selectedProductMods),function(mod,index){return _c('v-btn',{key:mod.id,staticClass:"ma-1",attrs:{"outlined":false,"rounded":"","large":"","dark":index === _vm.selectedMod
                ? _vm.orderTypeStyle.inDark
                : _vm.orderTypeStyle.outDark,"color":index === _vm.selectedMod
                ? _vm.orderTypeStyle.inColor
                : _vm.orderTypeStyle.outColor,"elevation":"0"},on:{"click":function($event){return _vm.selectMod(index)}}},[_vm._v(" "+_vm._s(mod.name)+" ")])}),1)],1),_c('v-row',_vm._l((_vm.getModProducts),function(product){return _c('v-col',{key:product.id,attrs:{"cols":"3"}},[_c('v-card',{staticClass:"grey lighten-4 overflow-hidden",attrs:{"flat":"","height":"250px"},on:{"click":function($event){return _vm.selectModToProd(product)}}},[_c('v-img',{attrs:{"aspect-ratio":"1.3","src":product.img
                  ? 'https://infiniti-kiosk-files-ekspil.amvera.io/api/v1/files/download/1/' +
                    product.img
                  : '/burger.png'}}),_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"ma-auto text-truncate"},[_vm._v(" "+_vm._s(product.name)+" ")])]),_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"text-subtitle-1 ma-auto"})]),(_vm.checkedMod(product))?_c('v-fab-transition',[_c('v-btn',{key:product.id + 'btn',staticClass:"v-btn--example mb-7 mr-0",attrs:{"fab":"","color":"success","dark":"","absolute":"","right":"","bottom":"","small":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")])],1)],1):_vm._e()],1)],1)}),1)],1),_c('v-footer',_vm._b({attrs:{"padless":true,"height":"80px"}},'v-footer',_vm.localAttrs,false),[_c('v-card',{staticClass:"ma-0 overflow-auto",attrs:{"color":"brown","elevation":"0","width":"100%","rounded":"0"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"brown text-center d-flex align-center justify-space-between mb-6",attrs:{"flat":"","tile":"","width":"100%"}},[_c('v-card',{attrs:{"flat":"","color":"brown","width":"30%"}},[_c('v-btn',{attrs:{"rounded":"","color":"error","dark":"","x-large":""},on:{"click":function($event){return _vm.helperOpenClose('CANCEL')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24px"}},[_vm._v(" mdi-cancel ")]),_vm._v(" Передумал ")],1)],1),_c('v-card',{attrs:{"flat":"","color":"grey lighten-4","width":"30%"}}),_c('v-card',{attrs:{"flat":"","color":"brown","width":"30%"}},[_c('v-btn',{attrs:{"rounded":"","color":"success","dark":"","x-large":"","disabled":!_vm.getButtonEndText().selectedItems[_vm.selectedMod]},on:{"click":function($event){_vm.helperOpenClose(_vm.getButtonEndText().action)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24px"}},[_vm._v(" mdi-chevron-right")]),_vm._v(" "+_vm._s(_vm.getButtonEndText().text)+" ")],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }