var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-overlay',{attrs:{"absolute":true,"value":_vm.productDescription.show},on:{"click":_vm.clearDelete}},[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(_vm.productDescription.product)?_c('v-card',{staticClass:"coupon_card",attrs:{"width":"700px","light":""}},[_c('v-img',{staticClass:"position_coupon",attrs:{"height":"100px","width":"100px","src":"/logo_black.png"}}),_c('v-card',{attrs:{"flat":"","align":"center"}},[_c('v-img',{attrs:{"transition":"scroll-y-transition","src":_vm.productDescription.product.img
              ? 'https://infiniti-kiosk-files-ekspil.amvera.io/api/v1/files/download/1/' +
                _vm.productDescription.product.img
              : '/burger.png',"aspect-ratio":"1.3","width":"400px"}}),_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"ma-auto"},[_vm._v(" "+_vm._s(_vm.getNameByLang(_vm.productDescription.product))+" ")])]),_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"text-subtitle-1 ma-auto wrap-nobreak"},[_vm._v(" "+_vm._s(_vm.productDescription.product.description)+" ")]),_c('div',{staticClass:"text-subtitle-1 ma-auto"},[_c('br'),_c('b',[_vm._v("Пищевая ценность на 100г. продукта:")]),_vm._v(" "+_vm._s(("Ккал: " + (_vm.productDescription.product.des_k) + ", Белки: " + (_vm.productDescription.product.des_p) + ", Жиры: " + (_vm.productDescription.product.des_l) + ", Углеводы: " + (_vm.productDescription.product.des_c)))+" ")])]),(_vm.productDescription.product && _vm.productDescription.product.helpers && _vm.helpers.length > 0)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"align-self-center"},[_vm._v(" Рекомендуем вместе: ")])]),_vm._l((_vm.helpers),function(product,index){return _c('v-col',{key:'helper' + index + product.id,attrs:{"cols":"3"}},[_c('v-card',{staticClass:"grey lighten-4 overflow-hidden",attrs:{"flat":"","height":"180px"},on:{"click":function($event){return _vm.productToCartHelper(product)}}},[(_vm.cardClass === product.id)?_c('v-img',{class:_vm.cardClass === product.id ? 'loader' : '',attrs:{"transition":"scroll-y-transition","aspect-ratio":"1.3","src":product.img
                    ? 'https://infiniti-kiosk-files-ekspil.amvera.io/api/v1/files/download/1/' +
                      product.img
                    : '/burger.png'}}):_vm._e(),_c('v-img',{attrs:{"transition":"scroll-y-transition","aspect-ratio":"1.3","src":product.img
                    ? 'https://infiniti-kiosk-files-ekspil.amvera.io/api/v1/files/download/1/' +
                      product.img
                    : '/burger.png'}}),_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"ma-auto text-truncate"},[_vm._v(" "+_vm._s(_vm.getNameByLang(product))+" ")])]),_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"text-subtitle-1 ma-auto"},[_vm._v(" "+_vm._s(product.price)+" "+_vm._s(_vm.getNameByLang(_vm.Rub))+" ")])])],1)],1)})],2)],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-2 mr-8 mb-3 mt-4",attrs:{"fab":"","dark":"","small":"","color":"error"},on:{"click":function($event){return _vm.productCount(-1)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus ")])],1),_c('v-card',{staticClass:"grey lighten-4 mb-3 mt-4",attrs:{"elevation":"0"}},[_c('h2',[_vm._v(_vm._s(_vm.productDescription.product.count ? _vm.productDescription.product.count : _vm.productDescription.product.count = 1))])]),_c('v-btn',{staticClass:"ml-8 mb-3 mt-4",attrs:{"fab":"","dark":"","small":"","color":"success"},on:{"click":function($event){return _vm.productCount(1)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('v-btn',{key:_vm.productDescription.product.id + 'btn',staticClass:"ml-15 pl-16 pr-16  mb-3 mt-4",attrs:{"color":"success","dark":"","bottom":"","large":"","min-width":"40%"},on:{"click":function($event){return _vm.productToCart(_vm.productDescription.product,  _vm.productDescription.product.count)}}},[_vm._v(" "+_vm._s(_vm.getNameByLang(_vm.AddToCart))+" ")]),_c('v-btn',{key:_vm.productDescription.product.id + 'btn_с',staticClass:"ml-15 pl-5 pr-5 mb-3 mt-4",attrs:{"color":"error","dark":"","large":"","bottom":"","min-width":"15%"},on:{"click":_vm.clearDelete}},[_vm._v(" "+_vm._s(_vm.getNameByLang(_vm.Cancel))+" ")])],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }